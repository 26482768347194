import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'
import '../src/css/style.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { metaMask } from './connectors/metaMask'
import './i18n/config'
import { useAppDispatch, useAppSelector } from './hooks/redux'
import { getUserExsists } from './store/api/apis'
import AddBlock from './Components/WalletInfo/AddBlock'
import { Eggs } from './Components/WalletInfo/EggsPage'
import { Index } from './Components/ConnectBlock/IndexPage'
import { Reg } from './Components/ConnectBlock/RegPage'
import { Balance } from './Components/ConnectBlock/BalancePage'

function App() {
    const { account, provider } = useWeb3React()
    const { page } = useAppSelector((state) => state.appSlice)
    const dispatch = useAppDispatch()
    // attempt to connect eagerly on mount
    useEffect(() => {
        void metaMask.connectEagerly().catch(() => {
            console.debug('Failed to connect eagerly to metamask')
        })
    }, [])

    useEffect(() => {
        if (!!account) {
            dispatch(getUserExsists({ address: account }))
        }
    }, [account])
    return (
        <>
            <div className="App wrapper">
                {page == 'INDEX' && !account && <Index />}
                {page == 'LOGIN' && <Reg />}
                {page == 'BALANCE' && <Balance />}
                {page == 'TUDSTAKING' && <AddBlock />}
                {page == 'EGGS' && <Eggs />}
                {/* {isMobile && <Deny/>} */}
                {/* {!account && !isMobile && <ConnectBlock />}
                {account && !isMobile && <WalletInfo />} */}
            </div>

            <ToastContainer
                position="bottom-right"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}

export default App
