import React, { useState } from 'react'

import { useWeb3React } from '@web3-react/core'

import { metaMask } from '../../../connectors/metaMask'
import { walletConnect } from '../../../connectors/walletConnect'
import { getEllipsisTxt } from '../../../utils/formatters'
import Jazzicons from '../Jazzicons'
import ConnectModal from '../ConnectModal/ConnectModal'
import DisconnectModal from '../DisconnectModal/DisconnectModal'

import styles from './ConnectAccount.module.scss'
import { useTranslation } from 'react-i18next'

interface WantedChain {
    chain?: number
}

const ConnectAccount: React.FC<WantedChain> = () => {
    const { account } = useWeb3React()
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false)
    const { t } = useTranslation()

    const disconnect = async () => {
        const connector = metaMask || walletConnect
        setIsModalVisible(false)
        setIsAuthModalOpen(false)
        localStorage.removeItem('connectorId')
        if (connector.deactivate) {
            connector.deactivate()
        } else {
            connector.resetState()
        }
        // @ts-expect-error close can be returned by wallet
        if (connector && connector.close) {
            // @ts-expect-error close can be returned by wallet
            await connector.close()
        }
    }

    return (
        <>
            {account === undefined ? (
                <>
                    <button onClick={() => setIsAuthModalOpen(true)} className="base_btn  btn_white_text purple_btn">
                        {t('Connect Wallet')}
                    </button>

                    <ConnectModal isModalOpen={isAuthModalOpen} setIsModalOpen={setIsAuthModalOpen} />
                    <br />
                </>
            ) : (
                <>
                    <div onClick={() => setIsModalVisible(true)} className={styles.accountDiv}>
                        <Jazzicons seed={account} />
                        {account && typeof account === 'string' && (
                            <p style={{ marginLeft: '8px' }}>{getEllipsisTxt(account, 6)}</p>
                        )}
                    </div>

                    <DisconnectModal
                        isModalOpen={isModalVisible}
                        setIsModalOpen={setIsModalVisible}
                        disconnect={disconnect}
                    />
                </>
            )}
        </>
    )
}

export default ConnectAccount
