import { useTranslation } from 'react-i18next'
import tud from '../../assets/img/tud.webp'
import tudsy from '../../assets/img/tudsy.webp'
import { useWeb3React } from '@web3-react/core'
import React, { useEffect } from 'react'
import { BNFT__factory, Mixer__factory, Operator__factory } from '../../typechain-types'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { getId, getNextPayoutDate, getSettings } from '../../store/api/apis'
import { appSlice } from '../../store/reducers/appSlice'

export const Balance = (): JSX.Element => {
    const { t } = useTranslation()
    const { account, provider } = useWeb3React()
    const [myCountTud, setMyCountTud] = React.useState<number>(0)
    const [myCountTudsy, setMyCountTudsy] = React.useState<number>(0)
    const [myStakedCount, setMyStakedCount] = React.useState<number>(0)
    const [mixedCount, setMixedCount] = React.useState<number>(0)
    const [displayAdd, setDisplayAdd] = React.useState(false)

    const { nextPayoutDate, stakingEnabled, mysteryEggsEnabled, userId } = useAppSelector((state) => state.appSlice)
    const { switchPage } = appSlice.actions
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getNextPayoutDate())
        dispatch(getSettings())
        dispatch(getId({address:account!}))
    }, [account])

    React.useEffect(
        () => {
            // Using an IIFE
            ;(async function anyNameFunction() {
                if (!provider) return

                const nftTudContract = BNFT__factory.connect(process.env.REACT_APP_NFT_CONTRACT_ADDRESS!, provider!)
                const nftTudsyContract = BNFT__factory.connect(process.env.REACT_APP_NFT2_CONTRACT_ADDRESS!, provider!)
                const operatorContract = Operator__factory.connect(process.env.REACT_APP_OPERATOR_ADDRESS!, provider!)
                const mixerContract = Mixer__factory.connect(process.env.REACT_APP_MIXER_ADDRESS!, provider!)
                try {
                    setMyCountTud((await nftTudContract.balanceOf(account!)).toNumber())
                    setMyCountTudsy((await nftTudsyContract.balanceOf(account!)).toNumber())
                    const myStakedCount = (await operatorContract.stakingBalance(account!)).toNumber()
                    const myMixedFromStakingCount = (await mixerContract.mixedBalanceFromStaking(account!)).toNumber()
                    setMyStakedCount(myStakedCount - myMixedFromStakingCount)
                    setMixedCount((await mixerContract.mixedBalance(account!)).toNumber())
                } catch (e: any) {
                    console.error(e)
                    toast.error(e)
                }
            })()
        },
        [
            /*account, displayAdd*/
        ]
    )

    const formatDucks = (count: string) => {
        return 'TUD'
    }

    return (
        <>
            <div className="duck_val" style={{paddingTop: "30px"}}>Ваш ID: {userId}</div>
            <div className="wrapper content pb80 balance_content">
                <div className="balance_grid">
                    <div className="part">
                        <img src={tud} alt="" className="t_img" />
                        <div className="details">
                            <div className="line">
                                <div className="position">{t('DUCK ON A Wallet:')}</div>
                                <div className="duck_val">{myCountTud} TUD</div>
                            </div>
                            <div className="line">
                                <div className="position">{t('mxd')}</div>
                                <div className="duck_val">{mixedCount} TUD</div>
                            </div>
                            <div className="line add_ducks_line">
                                <div className="position">{t('IN STAKE:')}</div>
                                <div className="duck_val">{myStakedCount} TUD</div>
                            </div>
                            {nextPayoutDate.length != 0 && (
                                <div className="line add_ducks_line">
                                    <div className="position date">{t('Следующая выплата:')}</div>
                                    <div className="duck_val date">{nextPayoutDate}</div>
                                </div>
                            )}
                        </div>
                        {stakingEnabled && (
                            <button
                                className="base_btn  btn_black_text yel_btn"
                                onClick={() => {
                                    if (myCountTud == 0) {
                                        toast.warning('No TUDS on wallet!')
                                    } else {
                                        dispatch(switchPage('TUDSTAKING'))
                                    }
                                }}
                            >
                                СТЕЙКИНГ TUD
                            </button>
                        )}
                    </div>
                    <div className="part">
                        <img src={tudsy} alt="" className="t_img" />

                        <div className="details">
                            <div className="line">
                                <div className="position">{t('DUCK ON A Wallet:')}</div>
                                <div className="duck_val">{myCountTudsy} TUDSY</div>
                            </div>
                            <div className="line">
                                <div className="position">{t('mxd')}</div>
                                <div className="duck_val">{mixedCount} TUDSY</div>
                            </div>
                        </div>
                        {mysteryEggsEnabled && (
                            <button
                                className="base_btn  btn_white_text purple_btn btnmargin"
                                onClick={() => dispatch(switchPage('EGGS'))}
                            >
                                Mystery egg’s
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
