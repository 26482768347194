import { useTranslation } from 'react-i18next'
import { ChangeEvent, useState } from 'react'
import { useAppDispatch } from '../../hooks/redux'
import { useWeb3React } from '@web3-react/core'
import { Header } from '../Header/Header'
import { saveUser } from '../../store/api/apis'
export const Reg = (): JSX.Element => {
    const { account, provider } = useWeb3React()
    const { t } = useTranslation()
    const [email, setEmail] = useState('')
    const dispatch = useAppDispatch()
    const saveUserHandler = () => {
        if (email && account) {
            dispatch(saveUser({ address: account, email: email }))
        }
    }
    const handleChange =
        (setter: React.Dispatch<React.SetStateAction<string>>) =>
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setter(event.target.value)
        }
    return (
        <>
            <Header />
            <div className="wrapper content add_ducks pb80">
                <h2 className="mb90">УКАЖИТЕ ВаШу почту на платформе metatokens:</h2>
                <div className="ducks_details mb90">
                    <div className="line add_ducks_line">
                        <div className="duck_val">
                            <div className="duck_val_text">email:</div>
                            <div className="input_border">
                                <input
                                    type="text"
                                    className="input_ducks"
                                    value={email}
                                    onChange={handleChange(setEmail)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className="connect_h2  h2firstrow" style={{ fontSize: '20px' }}>
                    *для вашего удобства начисления за стейкинг и выгрыши из mystery eggs будут начислены на ваш
                    аккаунт, на платформу Metatokens.
                </h2>
                <h2 className="connect_h2 h2firstrow" style={{ fontSize: '20px' }}>
                    Если у вас еще нет аккаунта, Пожалуйста предварительно зарегистрируйтесь на :
                </h2>
                <h2 className="connect_h2 h2firstrow rowpink" style={{ fontSize: '20px' }}>
                    https://metatokens.games
                </h2>

                <button className="base_btn btn_white_text purple_btn" onClick={saveUserHandler}>
                    ДАЛЕЕ
                </button>
            </div>
        </>
    )
}
