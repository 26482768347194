import { useWeb3React } from '@web3-react/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ERROR_CODE_TX_REJECTED_BY_USER } from '../../constants/networks'
import { BNFT__factory, Operator__factory } from '../../typechain-types'
import { increaseAllowance, isAllowed } from '../../utils/sharedFunctions'
import { appSlice } from '../../store/reducers/appSlice'
import { useAppDispatch } from '../../hooks/redux'
import Logo from '../../assets/img/logo.svg'

const AddBlock = (): JSX.Element => {
    const { account, provider } = useWeb3React()
    const [count, setCount] = React.useState(0)
    const [successStaked, setSuccessStaked] = React.useState(false)
    const [totalSupply, setTotalSupply] = React.useState(0)
    const { t } = useTranslation()
    const { switchPage } = appSlice.actions
    const dispatch = useAppDispatch()

    React.useEffect(() => {
        // Using an IIFE
        ;(async function anyNameFunction() {
            if (!provider) return
            const nftContract = BNFT__factory.connect(process.env.REACT_APP_NFT_CONTRACT_ADDRESS!, provider!)
            try {
                const ts = await nftContract.balanceOf(account!)
                setTotalSupply(ts.toNumber())
            } catch (e: any) {
                console.error(e)
                toast.error(e)
            }
        })()
    }, [account, successStaked])

    const onChange = (e: string) => {
        const newValue = Number.parseInt(e.replace(/\D/g, '')) || 0
        setCount(newValue)
    }

    async function stake() {
        if (count <= 0) return
        if (!account) {
            toast.info(t('Connect wallet'))
        }
        try {
            if (
                await isAllowed(
                    account!,
                    process.env.REACT_APP_NFT_CONTRACT_ADDRESS!,
                    provider!,
                    process.env.REACT_APP_OPERATOR_ADDRESS!
                )
            ) {
                const opContract = Operator__factory.connect(process.env.REACT_APP_OPERATOR_ADDRESS!, provider!)
                const result = await opContract.connect(provider!.getSigner()).stake(count, { gasLimit: 3000000 })
                toast
                    .promise(result.wait(), {
                        pending: t('Staking...')!,
                        success: t('Success!')!,
                        error: t('Error'),
                    })
                    .then((_) => setSuccessStaked(true))
            } else {
                await increaseAllowance(
                    process.env.REACT_APP_NFT_CONTRACT_ADDRESS!,
                    provider!,
                    process.env.REACT_APP_OPERATOR_ADDRESS!
                )
            }
        } catch (error: any) {
            if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                toast.info('TX_REJECTED_BY_USER')
                return
            } else {
                toast.error(error.message)
            }
        }
    }

    return (
        <>
            <div className="wrapper ">
                <div className="tt_logo">
                    <img src={Logo} alt="Ugly Ducks" className="logo_img" />
                </div>
            </div>
            {!successStaked && (
                <div className="wrapper add_ducks ptop">
                    <h2 className="add_h2">{t('SPECIFY THE NUMBER OF DUCKS TO ADD')}</h2>
                    <div className="ducks_details">
                        <div className="line add_ducks_line">
                            <div className="position">{t('Ducks count:')}</div>
                            <div className="input_border input_border_add">
                                <div className="add_duck_val">
                                    <input
                                        type="text"
                                        className="input_ducks"
                                        value={count}
                                        onChange={(e) => {
                                            onChange(e.target.value)
                                        }}
                                    />
                                    / {totalSupply}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="base_btn btn_black_text yel_btn" onClick={stake}>
                        {t('Add Ducks')}
                    </button>
                </div>
            )}
            {successStaked && (
                <div className="wrapper content wallet_info good pb80">
                    <h2 className="wallet_h2 ">УРА!</h2>
                    <h2 className="wallet_h2 wallet_bot">ВАШИ TUD ЗАСТЕЙКАНЫ 🥳</h2>
                    <div className="btn_flex">
                        <button
                            className="base_btn  whi_btn btn_black_text "
                            onClick={() => {
                                setCount(0)
                                setSuccessStaked(false)
                            }}
                            style={{ width: '250px' }}
                        >
                            НАЗАД
                        </button>
                        <button
                            className="base_btn yel_btn btn_black_text "
                            onClick={() => dispatch(switchPage('BALANCE'))}
                            style={{ width: '250px' }}
                        >
                            {t('My profile')}
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default AddBlock
