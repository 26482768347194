import { useTranslation } from 'react-i18next'
import EggImg from '../../assets/img/egg_1.webp'
import ConnectAccount from '../Account/ConnectAccount/ConnectAccount'
import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from '../../hooks/redux'
import { getUserExsists } from '../../store/api/apis'
import { Header } from '../Header/Header'
// import ConnectAccount from '../Account/ConnectAccount/ConnectAccount'

export const Index = (): JSX.Element => {
    const { account, provider } = useWeb3React()

    const { t } = useTranslation()

    return (
        <>
            <Header />
            <div className="wrapper connect pb80">
                <img src={EggImg} alt="Duck" className="egg_icon" />
                <h2 className="connect_h2 mb42">СКРЕЩИВАЙ TUD & Tudsy и получи Mystery EGGs!</h2>
                <ConnectAccount chain={Number.parseInt(process.env.REACT_APP_CHAIN_ID!)} />
            </div>
        </>
    )
}
