import { ethers } from 'ethers'
import { toast } from 'react-toastify'
import { BRC721__factory } from '../typechain-types'

export function getQueryVariable(variable: string) {
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
            return pair[1]
        }
    }
    return false
}

export const addressToPointsFormat = (address: string, firstPartTo: number, secondPartFrom: number): string => {
    return `${address.slice(0, firstPartTo)}....${address.slice(secondPartFrom)}`
}

export async function isAllowed(
    address: string,
    nftAddress: string,
    web3Provider: ethers.providers.Web3Provider,
    operatorContractAddress: string
): Promise<boolean> {
    if (!!address && !!nftAddress) {
        try {
            const assetContract = BRC721__factory.connect(nftAddress, web3Provider!)
            const result = await assetContract.isApprovedForAll(address, operatorContractAddress)
            return result
        } catch (e: any) {
            toast.error(e.message)
        }
    }
    return false
}

export async function increaseAllowance(
    nftAddress: string,
    web3Provider: ethers.providers.Web3Provider,
    operatorContractAddress: string
) {
    const nftContract = BRC721__factory.connect(nftAddress, web3Provider!)

    const result = await nftContract.connect(web3Provider!.getSigner()).setApprovalForAll(operatorContractAddress, true)

    toast.promise(result.wait(), {
        pending: 'Wait for transaction...',
        success: 'Allowance increased 👌',
        error: 'Error 🤯',
    })
}
