import { useTranslation } from 'react-i18next'
import Egg1 from '../../assets/img/egg1.webp'
import Egg2 from '../../assets/img/egg2.webp'
import Egg3 from '../../assets/img/egg3.webp'
import { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useWeb3React } from '@web3-react/core'
import { BNFT__factory, Mixer, Mixer__factory, Operator__factory } from '../../typechain-types'
import { toast } from 'react-toastify'
import { increaseAllowance, isAllowed } from '../../utils/sharedFunctions'
import { ERROR_CODE_TX_REJECTED_BY_USER } from '../../constants/networks'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { appSlice } from '../../store/reducers/appSlice'

interface EggData {
    classicCount: number
    specialCount: number
    platinumCount: number
}

export const Eggs = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const { switchPage } = appSlice.actions
    const { mixingCount } = useAppSelector((state) => state.appSlice)
    const [mixCount, setMixCount] = useState(0)
    const [myCountTud, setMyCountTud] = useState<number>(0)
    const [myCountTudsy, setMyCountTudsy] = useState<number>(0)
    const { t } = useTranslation()
    const { account, provider } = useWeb3React()
    const ref = useRef<HTMLDivElement>(null)
    const [popupOpen, setPopupOpen] = useState(false)
    useOnClickOutside(ref, () => setPopupOpen(false))
    const [eggs, setEggs] = useState<EggData>({ classicCount: 0, specialCount: 0, platinumCount: 0 })
    const [mixTrigger, setMixTrigger] = useState(0)
    const getEggsOfTypeCount = (mixed: Mixer.MixItemStructOutput[], eggType: number) => {
        return mixed.reduce((total, item) => {
            if (item.egg === eggType) {
                return total + 1
            }
            return total
        }, 0)
    }

    useEffect(() => {
        // Using an IIFE
        ;(async function anyNameFunction() {
            if (!provider) return

            const nftTudContract = BNFT__factory.connect(process.env.REACT_APP_NFT_CONTRACT_ADDRESS!, provider!)
            const nftTudsyContract = BNFT__factory.connect(process.env.REACT_APP_NFT2_CONTRACT_ADDRESS!, provider!)
            const mixerContract = Mixer__factory.connect(process.env.REACT_APP_MIXER_ADDRESS!, provider!)
            const operatorContract = Operator__factory.connect(process.env.REACT_APP_OPERATOR_ADDRESS!, provider!)
            try {
                const mixed = await mixerContract.getMixedTokens(account!)

                setEggs({
                    classicCount: getEggsOfTypeCount(mixed, 0),
                    specialCount: getEggsOfTypeCount(mixed, 1),
                    platinumCount: getEggsOfTypeCount(mixed, 2),
                })
                const tudsCount = (await nftTudContract.balanceOf(account!)).toNumber()
                const tudsStakedCount = (await operatorContract.stakingBalance(account!)).toNumber()
                const tudMixedCount = (await mixerContract.mixedBalanceFromStaking(account!)).toNumber()
                const myCountTud = tudsCount + tudsStakedCount - tudMixedCount
                setMyCountTud(myCountTud)
                const myCountTudsy = (await nftTudsyContract.balanceOf(account!)).toNumber()
                setMyCountTudsy(myCountTudsy)
                //console.log({ myCountTudsy })
                //console.log({ myCountTud })
                let mixCount = myCountTud > myCountTudsy ? myCountTudsy : myCountTud
                // console.log({ mixCount })
                mixCount = mixCount > mixingCount ? mixingCount : mixCount
                //console.log({ mixCount })
                setMixCount(mixCount)
                mixerContract.on('Mixed', (args) => {
                    setMixTrigger((prev) => prev + 1)
                })
            } catch (e: any) {
                console.error(e)
                toast.error(e)
            }
        })()
    }, [account, popupOpen, mixTrigger])

    async function mix() {
        if (mixCount == 0) {
            toast.warn('Нечего скрещивать!')
        }
        if ((myCountTud > myCountTudsy ? myCountTudsy : myCountTud) <= 0) {
            return
        }
        if (!account) {
            toast.info(t('Connect wallet'))
        }
        if (
            !(await isAllowed(
                account!,
                process.env.REACT_APP_NFT_CONTRACT_ADDRESS!,
                provider!,
                process.env.REACT_APP_MIXER_ADDRESS!
            ))
        ) {
            await increaseAllowance(
                process.env.REACT_APP_NFT_CONTRACT_ADDRESS!,
                provider!,
                process.env.REACT_APP_MIXER_ADDRESS!
            )
        }
        if (
            !(await isAllowed(
                account!,
                process.env.REACT_APP_NFT2_CONTRACT_ADDRESS!,
                provider!,
                process.env.REACT_APP_MIXER_ADDRESS!
            ))
        ) {
            await increaseAllowance(
                process.env.REACT_APP_NFT2_CONTRACT_ADDRESS!,
                provider!,
                process.env.REACT_APP_MIXER_ADDRESS!
            )
        }
        try {
            const mixContract = Mixer__factory.connect(process.env.REACT_APP_MIXER_ADDRESS!, provider!)

            const result = await mixContract.connect(provider!.getSigner()).mix(mixCount, { gasLimit: 3000000 })
            toast
                .promise(result.wait(), {
                    pending: t('Mixing...')!,
                    success: t('Success!')!,
                    error: t('Error'),
                })
                .then((_) => setPopupOpen(false))
        } catch (error: any) {
            if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                toast.info('TX_REJECTED_BY_USER')
                return
            } else {
                toast.error(error.message)
            }
        }
    }

    return (
        <>
            <div className="wrapper tt_logo  eggs pb40">
                <h2 className="eggs_h2">Данные по вашим mYSTERY EGGS:</h2>
                <div className="eggs_items">
                    <div className="egg_item">
                        <div className="egg_name">CLASSIC EGG</div>
                        <img src={Egg1} alt="Duck" className="egg_img" />
                        <div className="egg_balance duck_val">{eggs?.classicCount} eggs</div>
                    </div>

                    <div className="egg_item">
                        <div className="egg_name">SPECIAL EGG</div>
                        <img src={Egg2} alt="Duck" className="egg_img" />
                        <div className="egg_balance duck_val">{eggs?.specialCount} eggs</div>
                    </div>
                    <div className="egg_item">
                        <div className="egg_name">PlATINUM EGG</div>
                        <img src={Egg3} alt="Duck" className="egg_img" />
                        <div className="egg_balance duck_val">{eggs?.platinumCount} eggs</div>
                    </div>
                </div>
                <div className="btn_flex paddingbottom">
                    <button
                        className="base_btn  whi_btn btn_black_text "
                        onClick={() => dispatch(switchPage('BALANCE'))}
                    >
                        НАЗАД
                    </button>
                    <button className="base_btn  purple_btn fz26" onClick={() => setPopupOpen(true)}>
                        ПОЛУЧИТЬ
                    </button>
                </div>
            </div>
            {popupOpen && (
                <div className="eggs_popup" ref={ref}>
                    <h2 className="popup_h2" style={{ marginBottom: '2vh' }}>
                        Вы получите: {mixCount} EGGs*
                    </h2>
                    <h2 className="popup_h2" style={{ marginBottom: '10vh' }}>
                        * за одну транзакцию можно скрестить не более {mixingCount} TUD и TUDSY
                    </h2>
                    <div className="popup_items">
                        <div className="item">
                            <div className="text mb45 top_text">TUD</div>
                            <div className="text bottom_text">{myCountTud}</div>
                        </div>

                        <div className="item">
                            <div className="text mb45 top_text">X</div>
                        </div>

                        <div className="item">
                            <div className="text mb45 top_text">TUDSY</div>
                            <div className="text bottom_text">{myCountTudsy}</div>
                        </div>
                    </div>
                    <button className="base_btn   purple_btn white_btn  fz26 btnblack_text" onClick={mix}>
                        Скрестить
                    </button>
                </div>
            )}
        </>
    )
}
