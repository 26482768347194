import { useTranslation } from 'react-i18next'
import Logo from '../../assets/img/logo.svg'
import LogoImg from '../../assets/img/logo2.webp'

export const Header = (): JSX.Element => {
    const { t, i18n } = useTranslation()

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng)
    }

    return (
        <>           
            <div className="wrapper ">
                <div className="tt_logo">
                    <img src={LogoImg} alt="Ugly Ducks" className=" tt_img" />
                    {/* <img src={LogoImg} alt="Ugly Ducks" className="tt_img" /> */}
                </div>
            </div>
        </>
    )
}
