import ReactDOM from 'react-dom/client'
import App from './App'
import connectors from './connectors'
import { Buffer } from 'buffer'
import { Web3ReactProvider } from '@web3-react/core'
import { setupStore } from './store/store'
import { Provider } from 'react-redux'

if (!window.Buffer) window.Buffer = Buffer
const store = setupStore()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <Web3ReactProvider connectors={connectors}>
        <Provider store={store}>
            <App />
        </Provider>
    </Web3ReactProvider>
)
