// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CloseButton_closeBtn__dd4aW {\n  cursor: pointer;\n  position: absolute;\n  top: 13px;\n  right: 12px;\n}", "",{"version":3,"sources":["webpack://./src/Components/Account/CloseBtn/CloseButton.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;AACF","sourcesContent":[".closeBtn {\n  cursor: pointer;\n  position: absolute;\n  top: 13px;\n  right: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"closeBtn": "CloseButton_closeBtn__dd4aW"
};
export default ___CSS_LOADER_EXPORT___;
