import { createAsyncThunk } from '@reduxjs/toolkit'
import { ApiResponse, SettingsDto } from '../../types'
import { axiosClient } from './axiosClient'

interface IGetUserExsistsRequest {
    address: string
}

export const getUserExsists = createAsyncThunk<ApiResponse<boolean>, IGetUserExsistsRequest>(
    'appSlice/getUserExsists',
    async (args, { rejectWithValue }) => {
        try {
            const response = await axiosClient.get<ApiResponse<boolean>>('api/users/getUserExsists', {
                params: {
                    address: args.address,
                },
            })
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

interface ISaveUserRequest {
    address: string
    email: string
}

export const saveUser = createAsyncThunk<ApiResponse<boolean>, ISaveUserRequest>(
    'appSlice/saveUser',
    async (args, { rejectWithValue }) => {
        try {
            const response = await axiosClient.post<ApiResponse<boolean>>('api/users/saveUserCommand', args)
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const getNextPayoutDate = createAsyncThunk<ApiResponse<string>>(
    'appSlice/getNextPayoutDate',
    async (args, { rejectWithValue }) => {
        try {
            const response = await axiosClient.get<ApiResponse<string>>('api/settings/GetNextPayoutDate')
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const getSettings = createAsyncThunk<ApiResponse<SettingsDto>>(
    'appSlice/getSettings',
    async (args, { rejectWithValue }) => {
        try {
            const response = await axiosClient.get<ApiResponse<SettingsDto>>('/api/Settings/GetSettings')
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)
interface IUserIdRequest {
    address: string
}
export const getId = createAsyncThunk<ApiResponse<number>, IUserIdRequest>(
    'appSlice/getId',
    async (args, { rejectWithValue }) => {
        try {
            const response = await axiosClient.get<ApiResponse<number>>('/api/eggs/GetId'+'?address='+args.address)
            return response.data
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)