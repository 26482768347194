// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConnectButton_connectButtonText__6yllU {\n  font-weight: \"600\";\n  padding-left: \"10px\";\n}\n\n.ConnectButton_walletBtn__aoHE7 {\n  margin-top: 0px;\n  opacity: 1;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: left;\n  padding: 1rem;\n  background-color: rgb(232, 236, 251);\n  padding: 11px 15px;\n  outline: none;\n  border: 1px solid transparent;\n  border-radius: 12px;\n  font-size: 17px;\n  width: 100% !important;\n  line-height: 36px;\n  margin-bottom: 10px;\n  cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/Components/Account/ConnectButton/ConnectButton.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,oBAAA;AACF;;AACA;EACE,eAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,qBAAA;EACA,aAAA;EACA,oCAAA;EACA,kBAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;AAEF","sourcesContent":[".connectButtonText {\n  font-weight: \"600\";\n  padding-left: \"10px\";\n}\n.walletBtn {\n  margin-top: 0px;\n  opacity: 1;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: left;\n  padding: 1rem;\n  background-color: rgb(232, 236, 251);\n  padding: 11px 15px;\n  outline: none;\n  border: 1px solid transparent;\n  border-radius: 12px;\n  font-size: 17px;\n  width: 100% !important;\n  line-height: 36px;\n  margin-bottom: 10px;\n  cursor:pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"connectButtonText": "ConnectButton_connectButtonText__6yllU",
	"walletBtn": "ConnectButton_walletBtn__aoHE7"
};
export default ___CSS_LOADER_EXPORT___;
