import { useWeb3React } from '@web3-react/core'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getExplorer } from '../../../constants/networks'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import Address from '../Address/Address'
import styles from './DisconnectModal.module.scss'

interface ConnectModalProps {
    isModalOpen: boolean
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    disconnect: () => Promise<void>
}

const DisconnectModal: React.FC<ConnectModalProps> = ({ isModalOpen, setIsModalOpen, disconnect }) => {
    const { account, chainId } = useWeb3React()
    let refAddressDetails = useRef(null)
    useOnClickOutside(refAddressDetails, () => setIsModalOpen(false))
    const { t } = useTranslation()

    return (
        <>
            {isModalOpen && (
                <div ref={refAddressDetails} className={styles.addressDetails}>
                    <div
                        style={{
                            marginTop: '10px',
                            borderRadius: '1rem',
                            paddingLeft: '24px',
                            paddingRight: '24px',
                        }}
                    >
                        <Address avatar="left" size={6} copyable style={{ fontSize: '20px' }} />
                        <div
                            style={{
                                marginTop: '10px',
                                padding: '0 10px',
                                background: 'rgb(251, 17, 142)',
                                transition: 'background-color 125ms ease 0s',
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '12px',
                                paddingTop: '10px',
                                paddingBottom: '10px',

                                color: 'white',
                                border: 'none',
                            }}
                        >
                            {chainId !== undefined && (
                                <a
                                    href={`${getExplorer(chainId)}/address/${account}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        color: '#fff',
                                        textDecoration: 'none',
                                    }}
                                >
                                    <div style={{ marginRight: '5px' }} />
                                    {t('View on Explorer')}
                                </a>
                            )}
                        </div>
                        <button className={styles.disconnectBtn} onClick={() => disconnect()}>
                            {t('Disconnect Wallet')}
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default DisconnectModal
