import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiResponse, CurrentPage, SettingsDto } from '../../types'
import { getId, getNextPayoutDate, getSettings, getUserExsists, saveUser } from '../api/apis'

export const initialState = {
    page: 'INDEX' as CurrentPage,
    nextPayoutDate: '03.05.23',
    stakingEnabled: true,
    mysteryEggsEnabled: true,
    mixingCount: 20,
    userId:0
}

export const appSlice = createSlice({
    name: 'appSlice',
    initialState,
    reducers: {
        switchPage(state, action: PayloadAction<CurrentPage>) {
            state.page = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserExsists.fulfilled, (state, action: PayloadAction<ApiResponse<boolean>>) => {
                state.page = action.payload.payload ? 'BALANCE' : 'LOGIN'
            })
            .addCase(getUserExsists.rejected, (state, action: any) => {
                alert('Error ' + action.payload.Errors[0])
                state.page = 'INDEX'
            })
            .addCase(saveUser.fulfilled, (state, action: any) => {
                state.page = 'BALANCE'
            })
            .addCase(saveUser.rejected, (state, action: any) => {
                alert('Error ' + action.payload.Errors[0])
            })
            .addCase(getNextPayoutDate.fulfilled, (state, action: PayloadAction<ApiResponse<string>>) => {
                state.nextPayoutDate = action.payload.payload
            })
            .addCase(getNextPayoutDate.rejected, (state, action: any) => {
                state.nextPayoutDate = '-'
            })
            .addCase(getSettings.fulfilled, (state, action: PayloadAction<ApiResponse<SettingsDto>>) => {
                state.mysteryEggsEnabled = action.payload.payload.mysteryEggsEnabled
                state.stakingEnabled = action.payload.payload.stakingEnabled
                state.mixingCount = action.payload.payload.mixingCount
            })
            .addCase(getSettings.rejected, (state, action: any) => {
                state.mysteryEggsEnabled = false
                state.stakingEnabled = false
            })
            .addCase(getId.fulfilled, (state, action: PayloadAction<ApiResponse<number>>) => {
                state.userId=action.payload.payload
            })
    },
})

export default appSlice.reducer
