import { ethers } from 'ethers'
import { useTranslation } from 'react-i18next'
import coinbase_Logo from '../../../assets/images/coinbase_Logo.png'
import metamask_Logo from '../../../assets/images/metamask_Logo.svg'
import walletconnect_Logo from '../../../assets/svg/walletconnect_Logo.svg'
import { coinbaseWallet } from '../../../connectors/coinbaseWallet'
import { metaMask } from '../../../connectors/metaMask'
import { walletConnect } from '../../../connectors/walletConnect'
import { hasEthereum, isMetamask, isMobile } from '../../../constants/browsers'
import CloseButton from '../CloseBtn/CloseButton'
import ConnectButton from '../ConnectButton/ConnectButton'
import styles from './ConnectModal.module.scss'
import { toast } from 'react-toastify'

interface ConnectModalProps {
    isModalOpen: boolean
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ConnectModal: React.FC<ConnectModalProps> = ({ isModalOpen, setIsModalOpen }) => {
    const { t } = useTranslation()

    const activateConnector = async (label: string) => {
        try{
        switch (label) {
            case 'MetaMask':
                await metaMask.activate()
                /*alert(Number.parseInt(process.env.REACT_APP_CHAIN_ID!))*/
                await metaMask.provider?.request({
                    method: 'wallet_switchEthereumChain',
                    params: [
                        {
                            chainId: ethers.utils.hexStripZeros(
                                ethers.utils.hexlify(Number.parseInt(process.env.REACT_APP_CHAIN_ID!))
                            ),
                        },
                    ],
                })
                window.localStorage.setItem('connectorId', 'injected')
                break
            case 'WalletConnect':
                await walletConnect.activate()
                window.localStorage.setItem('connectorId', 'wallet_connect')
                break
            case 'Coinbase Wallet':
                await coinbaseWallet.activate()
                window.localStorage.setItem('connectorId', 'injected')
                break
            default:
                break
        }}catch(e:any){
            toast.error(e.message)
        }
    }

    function isMobileDevice() {
        return 'ontouchstart' in window || 'onmsgesturechange' in window
    }
    //alert(`ismpbile ${isMobile}; haseth:${hasEthereum}; ismm:${isMetamask}`)
    return (
        <>
            {isModalOpen && (
                <div className={styles.overlay}>
                    <div className={styles.connectModalWindow}>
                        <div>
                            <div className={styles.popupHeader}>
                                <div className={styles.modalTitle}>{t('Connect Wallet')}</div>
                                <CloseButton onClose={() => setIsModalOpen(false)} />
                            </div>
                            <div className={styles.walletList}>
                                {isMobile && !isMetamask ? (
                                    <a href={`https://metamask.app.link/dapp/${window.location.href}`}>
                                        <ConnectButton
                                            label="MetaMask"
                                            image={metamask_Logo}
                                            onClick={() => activateConnector('MetaMask')}
                                        />
                                    </a>
                                ) : (
                                    <ConnectButton
                                        label="MetaMask"
                                        image={metamask_Logo}
                                        onClick={() => activateConnector('MetaMask')}
                                    />
                                )}
                                <ConnectButton
                                    label="Coinbase Wallet"
                                    image={coinbase_Logo}
                                    onClick={() => activateConnector('Coinbase Wallet')}
                                />
                                <ConnectButton
                                    label="WalletConnect"
                                    image={walletconnect_Logo}
                                    onClick={() => activateConnector('WalletConnect')}
                                />
                                <div
                                    style={{
                                        margin: 'auto',
                                        fontSize: '15px',
                                        marginBottom: '0',
                                        marginTop: '12px',
                                    }}
                                >
                                    {t('Need help installing a wallet?')}
                                    <a
                                        href="https://metamask.zendesk.com/hc/en-us/articles/360015489471-How-to-Install-MetaMask-Manually"
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        {t('Click here')}
                                    </a>
                                </div>

                                <div
                                    style={{
                                        margin: 'auto',
                                        fontSize: '10px',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        paddingBottom: '20px',
                                    }}
                                >
                                    {t('Wallets are provided by External Providers')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ConnectModal
